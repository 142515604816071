.Logo {
  object-fit: contain;
  width: 100%;
  height: 120px;
  margin-top: 3rem;
}

.link-url {
  color: #2957A3;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Logo22 {
  position: relative;
  width: 134.91px;
  height: 120px;
  left: 40px;
  top: 89px;
}

.home_logo {
  width: 100%;
  height: auto;
}

.login-container {
  width: 30rem;
  border: 2px solid #c8c8c8;
  background: #fafafa;
  border-radius: 3%;
}

.login-form-container {
  margin: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.header {
  margin-bottom: 4.5rem;
}

.main-container {
  width: 100vw;
}

.table-container {
  max-height: 60vh;
}

.user {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.username {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  left: 79px;
  text-align: center;
  white-space: nowrap;
  margin: 0;
}

.userrank {
  left: 79px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.table thead tr th {
  font-size: large;
  font-weight: bold;
}

.table tbody tr td, .table thead tr th {
  text-align: center;
}

.docket-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 83rem;
  background-color: white;
  box-shadow: 24;
  border-radius: 10px;
  padding: 1rem;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55vw;
  background-color: white;
  box-shadow: 24;
  border-radius: 10px;
  padding: 2rem;
}

.doc-modal-container-form-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.doc-modal-container-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.doc-modal-container-para {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -0.2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 5rem 0rem;
}

.button-container {
  display: flex;
  justify-content: right;
}

.form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.range-picker{
  height: 3.5rem;
  border: rgb(185, 184, 184) solid 1px;
  width: 100%;
}

.range-picker:hover{
  border: black solid 1px;
}

.form-container-date {
  color: #2f67a3
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.auth-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0;
  padding: 0;
  height: 100vh;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-uploader-icon {
  color: #2957A3;
  max-width: 35px;
  min-width: 35px;
  max-height: 35px;
  min-height: 35px;
}

.file-uploader-para1 {
  color: #000000;
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
}

.file-uploader-para2 {
  color: #2957A3;
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
}

.file-uploader-para3 {
  color: #00000099;
  font-family: poppins;
}

.maintenance-container {
  display: grid;
  padding: 0rem 5rem;
}

.documents-container {
  display: grid;
  max-width: 100%;
}

.cards-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1rem;
  overflow-x: auto;
  padding: 0.5rem 0rem;
  max-width: 100%;
  position: relative;
  &::-webkit-scrollbar {
    width: 12px;               
  };
  &::-webkit-scrollbar-track {
    background: white;        /* color of the tracking area */
  };
  &::-webkit-scrollbar-thumb {
    background-color: rgb(221, 221, 221);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3.88px solid white;  /* creates padding around scroll thumb */
  };
}

/* .cards-container::after {
  content: '';
  position: fixed;
  inset: 0 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 10%, hsla(0, 0%, 100%, 0) 90%, hsla(0, 0%, 100%, 1) 100%);
} */

.card-container {
  width: 19rem;
  background-color: #eaecec !important; 
  display: flex; 
}

.card-content-delete-icon {
  position: absolute;
  inset: 1rem 1rem auto auto;
}

.card-content-delete-icon svg {
  aspect-ratio: 1;
  height: 1.25rem;
}

.signature-card-container {
  width: 19rem;
  height: 7rem;
  background-color: #eaecec !important;  
  display: flex;
}

.doc-sign-card-container {
  width: 22rem;
  height: 18rem;
  background-color: #eaecec !important;  
  display: flex;
}

.doc-sign-container {
  display: flex;
  gap: 1rem;
}

.doc-sign-container-left {
  flex: 5;
  flex-direction: column;
  gap: 1rem;
}

.doc-sign-container-right {
  flex: 5;
  flex-direction: column;
  gap: 1rem;
}

.party-sign-coordinate-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.party-sign-coordinate-card {
  width: 60%;
  
}

.parent-coordinate-card{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.helper-text{
  font-size: 14px;
  
}
.party-sign-card-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

.party-sign-card-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.party-sign-card-para {
 display: flex;
  
  width: 100%;
}

.party-sign-card-para-last{
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}

.party-coordinate-a{
  width: 120px;
  text-align: left;
}


.flex-container-column-gap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}


.flex-container-row-gap{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.pdf-viewer-button-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.pdf-viewer-page-no-container {
  display: flex;
  /* margin-left: auto; */
  gap: 1rem;
}

.buttons-section-pdf-viewer{
  width:160px;
  white-space: nowrap;
}

.button-text{
  text-transform: uppercase;
  font-size: 13px;
  
}
.pdf-viewer-canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #eaecec;
  /* border: 1px solid #000000; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;}

.pdf-viewer-drawing-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.pdf-viewer-page-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-purpose-card-container {
  width: 22rem;
  height: 13rem;
  background-color: #eaecec !important;  
  display: flex;
}

.email-purpose-doc-card-container {
  width: 22rem;
  height: 11rem;
  background-color: #eaecec !important;  
  display: flex;
}

.card-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  width: 100%;

}

.card-content-para {
    margin: 0rem;
    font-size: 15px;
}

.maintenance-header {
  font-size: large;
  font-weight: bold;
}

.document-card-container-para {
  text-align: center;
  font-size: 17.5px;
  font-weight: bold;
  margin: 0rem;
  margin-bottom: 0.2rem;
}